<template>
  <v-btn @click="click" outlined x-small>
    {{ $t("copySms") }}
  </v-btn>
</template>

<script>
export default {
  name: "TextCopyButton",

  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>
