<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="800">
    <v-card>
      <v-card-title class="display-1">
        {{ $t("confirmAlert") }}
      </v-card-title>
      <v-card-text>
        <v-alert type="warning" v-if="apiParams.Simulering">
          {{ $t("simModeActive") }}
        </v-alert>
        <v-alert type="warning" v-if="alertHasLargeGroupAndUsesDeputies">
          {{ $tc("largeGroupDeputyInfo", alertContactsPerGroupLimit) }}
          {{ $tc("youHaveSelected") }}
          {{ $tc("largeGroupsPlur", largeGroupsCount.groupCount) }}.
          {{ $t("largeGroupDeputyInfo2") }}
        </v-alert>
        <v-alert type="error" v-if="tooManyContacts">
          {{ $tc("tooManyContactsForAlert", alertContactsTotalLimit) }}
        </v-alert>
        <p class="headline">
          {{ $t("numberOfReceivers") }}:
          <strong v-if="largeGroupsCount.groupCount">
            {{ affectedReceiversCount + largeGroupsCount.membersCount }}
          </strong>
          <strong v-else>
            {{ affectedReceiversCount }}
          </strong>
        </p>
        <AlertMessagePreview :alert-params="apiParams" />
      </v-card-text>
      <v-card-actions class="mb-4">
        <v-spacer />
        <v-row>
          <!--          <v-col cols="12" md="6" xs="12">-->
          <v-btn
            :block="$vuetify.breakpoint.xs"
            @click="cancel"
            class="mr-5 mb-4 mt-1"
          >
            {{ $t("cancel") }}
          </v-btn>
          <!--          </v-col>-->
          <!--          <v-col cols="12" md="6" sm="12">-->
          <v-btn
            :block="$vuetify.breakpoint.xs"
            elevation="10"
            large
            class="success "
            @click="ok"
            :disabled="tooManyContacts"
            data-testid="confirmAlertDialog_ok"
          >
            {{ alertBtnText }}
          </v-btn>
          <!--          </v-col>-->
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store/index";
import contactUtil from "../../util/contactUtil";
import AlertParams from "@/api/v24/AlertParams";
import config from "@/config/config";
import AlertMessagePreview from "@/components/alert/AlertMessagePreview";

export default {
  name: "ConfirmAlertDialog",
  components: { AlertMessagePreview },
  props: {
    alertParams: {
      required: true,
      type: AlertParams
    }
  },

  data: () => ({
    state: store.state,
    dialog: true,
    apiParams: null,
    alertContactsPerGroupLimit: config.alertContactsPerGroupLimit,
    alertContactsTotalLimit: config.alertContactsLimit
  }),

  computed: {
    affectedReceiversCount() {
      let count = 0;
      store.state.alert.receivers.forEach(receiver => {
        if (
          contactUtil.contactWillBeIncludedInAlert(
            receiver,
            !!this.apiParams.Sms,
            !!this.apiParams.Epost,
            !!this.apiParams.Tale
          )
        ) {
          count++;
        }
      });
      return count;
    },
    largeGroupsCount() {
      let groupCount = 0;
      let membersCount = 0;
      store.state.alert.receivers.forEach(receiver => {
        if (receiver._source.name === "largeGroup") {
          groupCount++;
          membersCount += receiver.membersCount;
        }
      });
      return { groupCount: groupCount, membersCount: membersCount };
    },
    alertHasLargeGroupAndUsesDeputies() {
      const alertHasLargeGroup = store.state.alert.receivers.find(receiver => {
        return receiver._source.name === "largeGroup";
      });
      const alertUsesDeputies = this.alertParams.getUseDeputies();
      return alertHasLargeGroup && alertUsesDeputies;
    },
    tooManyContacts() {
      const contactsCount =
        this.affectedReceiversCount + this.largeGroupsCount.membersCount;
      return contactsCount > config.alertContactsLimit;
    },
    alertBtnText() {
      return this.alertParams.startTime
        ? this.$t("registerAlert")
        : this.$t("sendNow");
    }
  },

  created() {
    this.apiParams = this.alertParams.getApiParams();
  },

  methods: {
    ok() {
      this.$emit("ok");
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>
