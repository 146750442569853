<template>
  <div>
    <LoadingSpinner v-if="store.state.groups === null" />
    <v-row justify="end">
      <CreateGroupBtn
        :small="true"
        v-on:click="dialogCreateGroup = true"
        class="mr-6 mt-n4 mb-n2"
      />
    </v-row>
    <div v-if="store.state.groups">
      <v-text-field v-model="search" :label="$t('search')" class="mt-4" />
      <DataTable
        v-on:item-selected="toggle"
        v-on:toggle-select-all="toggleAll"
        v-on:update:options="onOptionsUpdate"
        v-model="store.state.alert.selectedGroups"
        :hide-default-footer="store.state.groups.length <= 10"
        :search="search"
        :custom-filter="filterList"
        :show-select="showSelect"
        :single-select="false"
        :headers="headers"
        item-key="Id"
        :items="store.state.groups"
        :items-per-page="itemsPerPage"
        class="elevation-1"
        mobile-breakpoint="0"
        data-testid="groupPickertable"
      >
        <template v-slot:item.actions="{ item }" v-if="usrHasEditPermission">
          <v-icon small @click="edit(item)" v-if="showEditIcon(item)">
            mdi-eye
          </v-icon>
        </template>
      </DataTable>
    </div>
    <v-dialog
      v-model="dialogGroupDetails"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="1200"
    >
      <GroupDetails
        v-if="group"
        :group-id="group.Id"
        :close-btn="true"
        v-on:close="groupDetailsDialogClosed"
        v-on:delete="groupDetailsDialogClosed"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogCreateGroup"
      v-if="dialogCreateGroup"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="1200"
    >
      <CreateGroup
        v-on:done="groupCreated"
        v-on:cancel="dialogCreateGroup = false"
      />
    </v-dialog>
  </div>
</template>

<script>
/**
 * Table with all groups user can pick from. Groups are fetched from Vuex.
 * Selected state is also held by Vuex.
 */
import LoadingSpinner from "../misc/LoadingSpinner";
import store from "../../store/index";
import GroupDetails from "../groups/GroupDetails";
import CreateGroup from "@/components/groups/CreateGroup";
import ContactSource from "@/util/ContactSource";
import apiGroup from "@/api/v24/api.group";
import config from "@/config/config";
import CreateGroupBtn from "@/components/groups/CreateGroupBtn";
import search from "@/util/search";
import DataTable from "@/components/dataTable/DataTable.vue";

export default {
  name: "GroupPicker",
  components: {
    DataTable,
    CreateGroupBtn,
    CreateGroup,
    GroupDetails,
    LoadingSpinner
  },
  computed: {
    itemsPerPage: {
      get() {
        return this.fuckingsBorregaard
          ? 20
          : store.getters.getPreferredNumTableRows;
      },
      set(value) {
        store.commit("setPreferredNumTableRows", value);
      }
    },
    headers() {
      let headers = [{ text: this.$t("name"), value: "Navn" }];
      const breakpointLgAndUp = this.$vuetify.breakpoint.lgAndUp;
      if (store.getters.getShowDepartmentInfoInLists && breakpointLgAndUp) {
        headers.push({
          text: this.$t("department"),
          value: "AvdelingNavn",
          cellClass: "font-weight-light"
        });
      }
      if (store.getters.getUserHasSmsCodeword && breakpointLgAndUp) {
        headers.push({
          text: this.$t("shortname"),
          value: "KodeNavn",
          cellClass: "font-weight-light"
        });
      }
      headers.push({
        text: "",
        value: "actions",
        sortable: false,
        align: "end"
      });
      return headers;
    }
  },
  data: () => ({
    showSelect: true,
    search: "",
    store: store,
    group: null,
    dialogGroupDetails: false,
    usrHasEditPermission: false,
    dialogCreateGroup: false,
    fuckingsBorregaard: false,
    tblFooterProps: {
      itemsPerPageOptions: config.fixedTblItemsPerPageOptions
    }
  }),
  created() {
    store.dispatch("updateUserProfile").then(usrProfile => {
      if (usrProfile.Kundenr === 32353) {
        this.fuckingsBorregaard = true;
      }
      if (usrProfile.Bruker._permissions.manageContacts) {
        this.usrHasEditPermission = true;
      }
    });
    store.dispatch("updateGroups");
  },
  methods: {
    onOptionsUpdate(options) {
      this.itemsPerPage = options.itemsPerPage;
    },
    filterList(value, query) {
      return search.whitespaceAsWildcard(value, query);
    },
    groupCreated(createdGroupData) {
      store.dispatch("updateGroups");
      this.dialogCreateGroup = false;
      if (!createdGroupData.wasImported) {
        this.group = createdGroupData.group;
        this.dialogGroupDetails = true;
      }
    },
    showEditIcon(group) {
      return !store.state.alert.selectedGroups.some(
        selGrp => selGrp.Id === group.Id
      );
    },
    edit(group) {
      this.group = group;
      this.dialogGroupDetails = true;
    },
    toggle(event) {
      const group = event.item;
      if (event.value === true) {
        this.addToReceivers([group]);
      } else {
        store.commit("removeSelectedAlertGroup", group.Id);
        this.removeReceivers(group);
      }
    },
    toggleAll(event) {
      if (event.value === true) {
        this.addToReceivers(event.items);
      } else {
        const groups = event.items;
        groups.forEach(group => {
          this.removeReceivers(group);
        });
      }
    },
    addToReceivers(groups) {
      this.showSelect = false;
      addAlertReceiversFromGroups(groups).then(() => {
        this.showSelect = true;
      });
    },
    groupDetailsDialogClosed() {
      this.dialogGroupDetails = false;
    },
    removeReceivers(group) {
      store.commit("removeAlertReceiverByGroupId", group.Id);
    }
  }
};

const addAlertReceiversFromGroups = groups => {
  return new Promise(resolve => {
    groups.forEach(group => {
      apiGroup.getNumberOfMembers(group.Id).then(numberOfMembers => {
        if (numberOfMembers > config.alertContactsPerGroupLimit) {
          addLargeGroup(group, numberOfMembers);
          resolve();
        } else {
          addAlertReceiversFromGroup(group).then(() => {
            resolve();
          });
        }
      });
    });
  });
};

const addLargeGroup = (group, membersCount) => {
  const contactSrc = new ContactSource();
  contactSrc.setLargeGroup(group.Id);
  const largeGroup = {
    name: group.Navn,
    membersCount: membersCount,
    _source: contactSrc.get()
  };
  store.commit("addAlertReceiver", largeGroup);
};

const addAlertReceiversFromGroup = group => {
  return new Promise(resolve => {
    apiGroup.getMembers(group.Id, 1, 9999999).then(res => {
      res.Medlem.forEach(contact => {
        const contactSource = new ContactSource();
        contactSource.setGroup(group.Id, contact.Id);
        // Adding source field with the group ID. We need this when user
        // deselect group
        contact._source = contactSource.get();
        store.commit("addAlertReceiver", contact);
      });
      resolve();
    });
  });
};
</script>
