<template>
  <v-container grid-list-md fluid>
    <v-layout row wrap>
      <v-flex xs12 md4>
        <ReceiverPicker />
      </v-flex>
      <v-flex xs12 md4 v-if="alertHasReceivers">
        <ReceiversList />
      </v-flex>
      <v-flex xs12 md4 class="d-none d-md-block" v-if="!alertHasReceivers">
        <v-img
          data-testid="logo"
          src="../assets/images/logo/mstile-310x310.png"
        ></v-img>
      </v-flex>
      <v-flex xs12 md4>
        <Composer />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Composer from "../components/alert/Composer";
import ReceiverPicker from "../components/receivers/ReceiverPicker";
import ReceiversList from "../components/receivers/ReceiversList";
import store from "../store/index";

export default {
  name: "Alert",
  components: { ReceiversList, ReceiverPicker, Composer },

  computed: {
    alertHasReceivers() {
      return !!store.state.alert.receivers.length;
    }
  }
};
</script>
