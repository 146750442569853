<template>
  <v-card>
    <v-card-title>
      {{ $t("newGroup") }}
    </v-card-title>
    <v-card-text>
      <v-radio-group row v-model="creationType">
        <v-radio :label="$t('createManually')" value="normal"></v-radio>
        <v-radio :label="$t('importFromFile')" value="import"></v-radio>
      </v-radio-group>
      <NewGroupForm
        id="new-group-form"
        v-if="creationType === 'normal'"
        v-on:done="done"
        v-on:cancel="cancel"
      />
      <GroupImport
        v-if="creationType === 'import'"
        file-type="csv"
        v-on:cancel="cancel"
        v-on:done="importDone"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import GroupImport from "../import/GroupImport";
import NewGroupForm from "./NewGroupForm";
import user from "@/util/user";

export default {
  name: "CreateGroup",
  components: { NewGroupForm, GroupImport },

  data: () => ({
    creationType: "normal"
  }),

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    done(group) {
      this.emitDone(group, false);
    },
    importDone(group) {
      this.emitDone(group, true);
    },
    emitDone(group, wasImported) {
      this.$emit("done", {
        group: group,
        wasImported: wasImported
      });
    }
  }
};
</script>

<style scoped>
#new-group-form {
  max-width: 800px;
}
</style>
