<template>
  <div>
    <v-form @submit.prevent="search" autocomplete="off">
      <v-card>
        <v-card-title>
          {{ $t("searchFolkereg") }}
        </v-card-title>
        <v-card-text>
          <KommuneAutocomplete v-on:change="setKommune" />
          <v-text-field
            autofocus
            outlined
            :label="getLabelFirstName()"
            v-model.trim="firstName"
            v-if="kommune"
          />
          <v-text-field
            outlined
            :label="$t('surname')"
            v-model.trim="surname"
            v-if="kommune"
          />
          <v-btn type="submit" :disabled="!kommune || !surname">
            {{ $t("search") }}
          </v-btn>

          <div class="mt-6">
            <LoadingSpinner v-if="this.searching" />

            <div v-if="!searching && searchResult">
              <h2>{{ $t("searchResult") }}</h2>
              <v-alert type="warning" v-if="!searchResult.length">
                {{ $t("noMatch") }}
              </v-alert>
              <v-list-item
                three-line
                v-if="searchResult.length"
                v-for="res in searchResult"
                :key="res.Id"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ res.Navn }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ res.Adresse.Adresse }},
                    {{ res.Adresse.Kommune }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t("born") }}: {{ getBirthDate(res.FodtDato) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    color="secondary"
                    @click="add(res)"
                    :disabled="personAlreadyAdded(res.Id)"
                  >
                    {{ $t("add") }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
    <v-dialog v-model="dialogDsfError" max-width="550">
      <v-card>
        <v-card-title>
          {{ $t("error") }}
        </v-card-title>
        <v-card-text>
          <v-alert type="warning">
            {{ dsfErrorTxt }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogDsfError = false">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import KommuneAutocomplete from "@/components/misc/KommuneAutocomplete";
import apiDsf from "@/api/v24/api.dsf";
import LoadingSpinner from "@/components/misc/LoadingSpinner";
import store from "@/store";
import Contact from "@/util/Contact";
import PhoneNumber from "@/util/PhoneNumber";
import ContactSource from "@/util/ContactSource";
import eventLogger from "@/util/eventLogger";

export default {
  name: "DsfPicker",
  components: { LoadingSpinner, KommuneAutocomplete },
  data: () => ({
    firstName: "",
    surname: "",
    kommune: null,
    searchResult: null,
    searching: false,
    dialogDsfError: false,
    dsfErrorTxt: ""
  }),

  methods: {
    getLabelFirstName() {
      return (
        this.$t("firstName") + " (" + this.$t("optional").toLowerCase() + ")"
      );
    },
    setKommune(kommune) {
      this.kommune = kommune;
    },
    getBirthDate(isoDate) {
      return this.$d(new Date(isoDate), "short");
    },
    search() {
      apiDsf
        .searchName(this.kommune.Nr, this.surname, this.firstName)
        .then(res => {
          this.searchResult = res;
        });
    },
    personAlreadyAdded(persId) {
      return !!store.state.alert.receivers.find(receiver => {
        const src = receiver._source;
        return src.name === "dsf" && src.id === persId;
      });
    },
    add(person) {
      apiDsf.getPersonInfo(person.Id).then(info => {
        if (!info.KontaktInfo.length) {
          this.showDsfErrorDialog(this.$t("dsfInvalidContactInfo"));
          return;
        }
        const phoneStr = parseDsfPhone(info);
        const emailStr = parseDsfEmail(info);
        if (!phoneStr && !emailStr) {
          this.showDsfErrorDialog(this.$t("dsfInvalidContactInfo"));
          return;
        }
        const phoneNum = new PhoneNumber(phoneStr, "+47");
        const personPhone = phoneNum.getAsApiFormat();
        const dsfPerson = new Contact(person.Navn);
        if (personPhone.type === "M") {
          dsfPerson.setMobile1(personPhone.countryCode + personPhone.number);
        } else if (personPhone.type === "F") {
          dsfPerson.setLandline1(personPhone.countryCode + personPhone.number);
        }
        if (emailStr) {
          dsfPerson.setEmail(emailStr);
        }
        const source = new ContactSource();
        source.setDsf(person.Id);
        dsfPerson.setSource(source);
        const apiObject = dsfPerson.getAsApiObject();
        store.commit("addAlertReceiver", apiObject);
        eventLogger.receiversAddedFromDsf();
      });
    },
    showDsfErrorDialog(errTxt) {
      this.dsfErrorTxt = errTxt;
      this.dialogDsfError = true;
    }
  }
};
const parseDsfPhone = dsfContact => {
  return parseDsfContactInfo(dsfContact, "M");
};

const parseDsfEmail = dsfContact => {
  return parseDsfContactInfo(dsfContact, "E");
};

const parseDsfContactInfo = (dsfContact, type) => {
  const phoneStr = dsfContact.KontaktInfo.find(contact => {
    return contact.substr(0, 1) === type;
  });
  if (!phoneStr || phoneStr.length < 3) {
    return "";
  }
  return phoneStr.substr(2);
};
</script>
