import config from "../../config/config";
import i18n from "../../i18n";
import alertResponseOptions from "@/util/alertResponseOptions";
import SmsSender from "@/util/SmsSender";
import sms from "@/util/sms";
import user from "@/util/user";
import emailUtil from "@/util/EmailUtil";
import { DateTime } from "luxon";

export default class AlertParams {
  constructor(name, simMode, receivers) {
    this.name = name;
    this.simMode = simMode;
    this.receivers = parseContacts(receivers);
    this.largeGroups = parseLargeGroups(receivers);
    this.sms = null;
    this.email = null;
    this.voice = null;
    this.useDeputies = false;
    this.responseOptions = null;
    this.defaultEmailSenderAddr = config.defaultEmailSenderAddr;
    this.audioFile = null;
    this.department = "";
    this.numberTypeFilter = 0;
    this.meta = [];
  }

  setSms(text, sender) {
    this.sms = {
      sender: sender,
      text: text
    };
  }

  setEmail(title, text, sender, attachments = []) {
    this.email = {
      title: title,
      text: text,
      sender: sender,
      attachments: attachments
    };
  }

  /**
   * @param {Object} voiceProps Object of same type as returned from API method GetVoices
   * @param {String} textToConvertToSpeech
   */
  setVoice(voiceProps, textToConvertToSpeech) {
    this.voice = {
      voiceProps: voiceProps,
      text: textToConvertToSpeech
    };
  }

  setVoiceAttempts(maxAttempts, delayBetweenAttempts) {
    this.voice = this.voice || {};
    this.voice.attempts = {
      maxAttempts: maxAttempts,
      delayBetweenAttempts: delayBetweenAttempts
    };
  }

  setAudioFile(fileId) {
    this.audioFile = fileId;
  }

  setUseDeputies(boolState) {
    this.useDeputies = boolState;
  }

  /**
   *
   * @param {Object} meta Instance from AlertMeta.js
   */
  setMeta(alertMetaInstance) {
    this.meta = alertMetaInstance.getMeta();
  }

  /**
   * @name setEndOnState
   * Sets the end on state value.
   * The API is very weird when it comes to RetryOption.EndOnState. This
   * field is referenced to when alert server determines if new attempt should
   * be done on a receiver AND it's used to determine if deputy should be used.
   * @param {String} state Either "active" or "confirmed". active: End calling if
   * receiever has answered the call (Note: May be answered by a machine).
   * confirm: End calling when receiver has confiremd (pressed a digit)
   */
  setEndOnState(state) {
    this.voice = this.voice || {};
    this.voice.endOnState = state;
  }

  /**
   * @name setResponseOptions
   * @param resOptions In custom Group Alert format:
   [
   { num: "1", value: "", customValue: "", useCustomValue: false, link: "" },
   { num: "2", value: "", customValue: "", useCustomValue: false, link: "" },
   { num: "3", value: "", customValue: "", useCustomValue: false, link: "" },
   { num: "4", value: "", customValue: "", useCustomValue: false, link: "" },
   { num: "5", value: "", customValue: "", useCustomValue: false, link: "" },
   { num: "6", value: "", customValue: "", useCustomValue: false, link: "" },
   { num: "7", value: "", customValue: "", useCustomValue: false, link: "" },
   { num: "8", value: "", customValue: "", useCustomValue: false, link: "" }
   ]
   */
  setResponseOptions(resOptions) {
    this.responseOptions = resOptions;
  }

  setStartTime(startTime, timezone) {
    const normTime = normalizeStartTime(startTime);
    this.startTime = DateTime.fromISO(normTime, { zone: timezone }).toISO();
  }

  setDepartment(department) {
    this.department = department;
  }

  /**
   * @name setNumberTypeFilter
   * Set filter to only alert work or private phone numbers. Set to 1 for work,
   * 2 for private
   * @param { numberType } numberType
   */
  setNumberTypeFilter(numberType) {
    this.numberTypeFilter = numberType;
  }

  validate() {
    const errors = [];
    if (
      (!this.largeGroups || !this.largeGroups.length) &&
      (!this.receivers || !this.receivers.length)
    ) {
      errors.push(i18n.t("receiversMissing"));
    }
    if (!this.name) {
      errors.push(i18n.t("alertNameMissing"));
    }
    if (!this.sms && !this.email && !this.voice) {
      errors.push(i18n.t("channelMissing"));
    }
    if (this.sms) {
      if (!this.sms.sender) {
        errors.push(i18n.t("smsSenderMissing"));
      } else {
        const smsSender = new SmsSender(this.sms.sender);
        const smsSenderError = smsSender.validate();
        if (smsSenderError) {
          errors.push(smsSenderError);
        }
      }
      if (!this.sms.text) {
        errors.push(i18n.t("smsTxtMissing"));
      } else if (sms.findInvalidSymbols(this.sms.text)) {
        errors.push(i18n.t("smsTextIsInvalid"));
      }
    }
    if (this.email) {
      if (!this.email.title) {
        errors.push(i18n.t("emailTitleMissing"));
      } else {
        dynamicTextIsUsed(this.email.title, errors, "dynEmailTitleFail");
      }

      if (!this.email.text) {
        errors.push(i18n.t("emailTxtMissing"));
      }
      if (!this.email.sender) {
        errors.push(i18n.t("emailSenderMissing"));
      }
      if (this.email.attachments.length) {
        const attachmentData = emailUtil.attachmentsFileSizeIsOk(
          this.email.attachments
        );
        if (!attachmentData.sizeOk) {
          errors.push(
            i18n.t("totFileSizeError", { count: attachmentData.limit })
          );
        }
      }
    }
    if (this.voice) {
      if (!this.voice.voiceProps || !this.voice.voiceProps.Name) {
        errors.push(i18n.t("voiceMissing"));
      }
      if (!this.voice.text && !this.audioFile) {
        errors.push(i18n.t("voiceTxtMissing"));
      } else {
        dynamicTextIsUsed(this.voice.text, errors, "dynVoiceFail");
      }
      if (
        getNumOfVoiceReceivers(this.receivers) > config.alertVoiceContactsLimit
      ) {
        errors.push(
          i18n.tc("voiceContactsLimit", config.alertVoiceContactsLimit)
        );
      }
    }
    return errors;
  }

  getUseDeputies() {
    return this.useDeputies;
  }

  getNumberTypeFilter(numberType) {
    return this.numberTypeFilter;
  }

  getApiParams() {
    const sendSms = !!this.sms;
    const sendEmail = !!this.email;
    const sendVoice = !!this.voice;

    let params = {
      VarslingNavn: this.name,
      Simulering: this.simMode,
      Mottakere: createReceiversArray(
        this.receivers,
        sendSms,
        sendEmail,
        sendVoice,
        this.useDeputies
      ),
      TjenesteFilter: this.numberTypeFilter,
      Grupper: [],
      Tidspunkt: this.startTime ? this.startTime : ""
    };
    if (this.largeGroups) {
      params.Grupper = this.largeGroups.map(largeGroup => {
        return largeGroup._source.id;
      });
    }
    if (this.sms) {
      params.Sms = {
        Avsender: this.sms.sender,
        Melding: this.sms.text
      };
    }
    if (this.email) {
      params.Epost = {
        AvsenderNavn: this.email.sender,
        Avsender: this.defaultEmailSenderAddr,
        Tittel: this.email.title,
        Melding: this.email.text,
        Attachments: this.email.attachments.map(att => {
          return { FileId: att.FileId };
        })
      };
    }
    if (this.voice) {
      params.Tale = {
        Voice: this.voice.voiceProps.Id,
        Language: this.voice.voiceProps.LanguageCode,
        Content: this.voice.text,
        AudioFileId: this.audioFile || "",
        Choices: {},
        Introduction: {
          Text: "",
          PressKey1ToContinue: false
        },
        RepeatOption: {
          Enabled: true,
          Key: 9
        },
        ConferenceOption: {
          Enabled: false,
          Key: 0,
          Name: "",
          Recording: false
        }
      };

      if (this.voice.attempts) {
        // Add one since API uses "MAX total calls attempts" where the first call
        // is attempt 1
        const attempts = this.voice.attempts.maxAttempts + 1;
        params.Tale.RetryOption = {
          MaxCalls: attempts,
          Delay: this.voice.attempts.delayBetweenAttempts * 60
        };
      }
      if (this.voice.endOnState) {
        params.Tale.RetryOption = params.Tale.RetryOption || {};
        params.Tale.RetryOption.EndOnState = this.voice.endOnState;
      }
    }

    if (this.responseOptions) {
      // Add response link to alert text
      if (this.sms) {
        params.Sms.Melding += "\n" + config.responseUrl;
      }
      if (this.email) {
        params.Epost.Melding += "\n\n" + config.responseUrl;
      }

      params.Svar = alertResponseOptions.getApiFormatSmsEmail(
        this.responseOptions
      );
      if (this.voice) {
        const voiceParams = alertResponseOptions.getApiFormatVoice(
          this.responseOptions,
          this.voice.voiceProps.LanguageCode
        );
        params.Tale.Choices = voiceParams.choices;
        params.Tale.Confirm = voiceParams.confirmText;
      }
    } else if (!this.responseOptions && this.voice) {
      params.Tale.Choices = alertResponseOptions.getApiFormatVoice(
        null,
        this.voice.voiceProps.LanguageCode
      ).choices;
    }

    if (this.meta.length) {
      params.Kommentarer = this.meta;
    }

    params.Avdeling = this.department;
    return params;
  }
}

/**
 * Creates receivers params array from array with contact data in this format
 [
 {
		"Vikar2": 0,
		"Vikar1": 0,
		"Stilling": "B1 - etg 3 - brannvern",
		"KontaktId": 14417,
		"GruppeId": 1012,
		"Mobil": "+4799887777",
		"Fast": ""
		"Navn": "Krokstad, John",
	},
 ...
 ]

 * Will be mapped to this format
 [
 {
      "Navn": "Person 1",
      "KontaktInfo": [
        "M:{{mobil}}"
      ]
      "Vikarer": [
      {
          "Navn": "Vikar 1",
          "KontaktInfo": [
              "M:{{mobilVikar}}"
          ]
        }
      ]
    }
 ]
 */
const createReceiversArray = (
  contacts,
  sendSms,
  sendEmail,
  sendVoice,
  useDeputies
) => {
  return contacts.map(contact => {
    let contactDataObjects = [];
    if (sendVoice || sendSms) {
      if (contact.Mobil) {
        contactDataObjects.push(createPhoneDataObj(contact, "Mobil"));
      }
      if (contact.Mobil2) {
        contactDataObjects.push(createPhoneDataObj(contact, "Mobil2"));
      }
      if (contact.Mobil3) {
        contactDataObjects.push(createPhoneDataObj(contact, "Mobil3"));
      }
    }
    if (contact.Fast && sendVoice) {
      contactDataObjects.push(createPhoneDataObj(contact, "Fast"));
    }
    if (contact.Fast2 && sendVoice) {
      contactDataObjects.push(createPhoneDataObj(contact, "Fast2"));
    }
    if (contact.Epost && sendEmail) {
      contactDataObjects.push(createPhoneDataObj(contact, "Epost"));
    }

    const receiverData = {
      Navn: contact.Navn,
      KontaktInfo: contactDataObjects,
      Kommentar: contact.Kommentar
    };

    if (useDeputies && contact.Vikarer) {
      receiverData.Vikarer = contact.Vikarer;
    }
    return receiverData;
  });
};

const createPhoneDataObj = (contact, targetField) => {
  let phoneType;
  if (targetField === "Epost") {
    phoneType = "E";
  } else if (targetField.search("Mobil") >= 0) {
    phoneType = "M";
  } else {
    phoneType = "F";
  }
  return {
    Data: contact[targetField],
    Type: phoneType,
    Tjeneste: phoneType === "E" ? 0 : contact[targetField + "Type"]
  };
};

const parseContacts = receivers => {
  return receivers.filter(receiver => receiver._source.name !== "largeGroup");
};

const parseLargeGroups = receivers => {
  return receivers.filter(receiver => receiver._source.name === "largeGroup");
};

const getNumOfVoiceReceivers = receivers => {
  let hits = 0;
  receivers.forEach(receiver => {
    if (receiver.Mobil || receiver.MobilPrivat || receiver.Fast) {
      hits++;
    }
  });
  return hits;
};

const dynamicTextIsUsed = (text, errors, i18nErrMsgKey) => {
  config.dynamicNameTags.forEach(nameTag => {
    let tag = "[" + nameTag + "]";
    if (text.indexOf(tag) !== -1) {
      errors.push(i18n.t(i18nErrMsgKey, { tag: tag }));
    }
  });
};

/**
 * We had a support issue (https://framweb.zendesk.com/agent/tickets/3826) where
 * time format was missing leading 0 in hour value. Error message in Slack
 * #alarm:
 *
 * [Hermes] TVxDB.RollbackTransaction: Exception: IsoToDateTime: Ugyldig
 * DateTime: 2021-03-23T9:00 Error: Ugyldig ISO format: 2021-03-23T9:00.
 *
 * This is strange since the Vuetify component we use, v-time-picker, always
 * seem to set hour value to two digits (for ex. "09:00") in both 24hr and
 * AM/PM mode.
 *
 * I've tried to reproduce in IE, Edge (but not the old version...), FF,
 * Chrome and Opera. Without luck.
 *
 * Later customer said he was using Chrome.
 *
 * Anyways, this function should fix the issue.
 *
 * Erik
 *
 * @param {String} startTime
 * @return {String} Normalized format
 */
const normalizeStartTime = startTime => {
  if (!startTime) {
    return startTime;
  }
  const timeSplit = startTime.split("T");
  if (timeSplit[1].length === 4) {
    return timeSplit[0] + "T" + "0" + timeSplit[1];
  }
  return startTime;
};
