<template>
  <v-autocomplete
    v-model="timezone"
    :items="timezones"
    outlined
    :label="$t('timezone')"
    dense
    item-value="tzCode"
    item-text="label"
    return-object
    @change="emitChange"
  />
</template>

<script>
import timezones from "timezones-list";

export default {
  name: "timezoneSelect",

  props: {
    initTimezone: {
      type: String,
      required: true
    }
  },

  computed: {
    timezones() {
      return timezones;
    }
  },

  data: () => ({
    timezone: ""
  }),

  created() {
    this.timezone = this.initTimezone;
  },

  methods: {
    emitChange() {
      if (this.timezone) {
        this.$emit("change", this.timezone);
      }
    },
  }
};
</script>