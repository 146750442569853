<template>
  <v-btn @click="click" :small="small">
    <v-icon left>mdi-plus</v-icon>
    {{ $t("createNewContact") }}
  </v-btn>
</template>

<script>

export default {
  name: "CreateContactBtn",

  props: {
    small: {
      default: false,
      type: Boolean
    }
  },

  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>
