<template>
  <v-autocomplete
    v-if="templates && templates.length"
    v-model="selectedTemplate"
    @change="change"
    solo
    dense
    :items="templates"
    item-text="name"
    return-object
    :label="getLabel()"
  />
</template>

<script>
import store from "../../store";

export default {
  name: "AlertTemplatesSelect",

  computed: {
    templates() {
      return store.state.alertTemplates;
    }
  },

  data: () => ({
    selectedTemplate: null
  }),

  methods: {
    getLabel() {
      return (
        this.$t("alertTemplate") +
        " (" +
        this.$t("optional").toLowerCase() +
        ")"
      );
    },
    change() {
      this.$emit("change", this.selectedTemplate);
    }
  }
};
</script>
