<template>
  <div>
    <v-card :loading="!userProfile">
      <v-card-title>
        {{ $t("alert") }}
      </v-card-title>
      <v-card-text v-if="userProfile">
        <DepartmentSelect
          :dense="true"
          :hint="$t('alertDepartmentInfo')"
          :init-department="settings.department"
          v-on:change="setDepartment"
        />
        <AlertTemplatesSelect
          v-on:change="setSettingsFromTemplate"
          :key="templCompKey"
          data-testid="alertTemplateSelectComposer"
        />
        <v-form autocomplete="off">
          <v-text-field
            v-model.trim="settings.name"
            maxlength="50"
            outlined
            :label="$t('alertName')"
            class="mb-n3"
            data-testid="alertName"
            id="alert-name"
          ></v-text-field>
          <SmsComposer
            :init-enabled="settings.sendSms"
            :init-sender="settings.sms.sender"
            :init-text="settings.sms.text"
            v-on:change="setSmsSettings"
            :key="smsCompKey"
            :auto-sms-sender="true"
          />
          <EmailComposer
            :init-enabled="settings.sendEmail"
            :init-title="settings.email.title"
            :init-text="settings.email.text"
            v-on:change="setEmailSettings"
            :key="emailCompKey"
          >
            <template v-slot:footer>
              <div v-if="settings.sendSms">
                <TextCopyButton v-on:click="copySmsTextToEmail" />
              </div>
              <div v-if="userProfile.Bruker._permissions.dsfModule">
                <v-btn
                  @click="addSourceInfoToEmailText"
                  x-small
                  outlined
                  class="mr-2"
                >
                  {{ $t("addSourceInfo") }}
                </v-btn>
                <v-tooltip bottom max-width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("addSourceInfoBtnDesc") }}</span>
                </v-tooltip>
              </div>
            </template>
          </EmailComposer>
          <VoiceComposer
            v-if="settings.voice.voiceProps"
            :init-voice-props="settings.voice.voiceProps"
            :init-enabled="settings.sendVoice"
            :init-text="settings.voice.text"
            :init-attempts="settings.voice.attempts"
            :init-delay="settings.voice.delay"
            :init-deputy="settings.voice.deputy"
            :init-panel-state="settings.voice.advancedOptPanel"
            v-on:change="setVoiceSettings"
            :key="voiceCompKey"
            :show-sound-file-option="true"
          >
            <template v-slot:text-footer v-if="settings.sendSms">
              <TextCopyButton v-on:click="copySmsTextToVoice" />
            </template>
          </VoiceComposer>

          <ResponseComposer
            :init-enabled="settings.sendResponseOptions"
            :in-responses="settings.responses"
            v-on:change="setResponseSettings"
            :key="resCompKey"
          />

          <v-switch
            v-model="simMode"
            :label="$t('simMode')"
            class="mb-n6"
            data-testid="simMode"
          />

          <div
            v-bind:class="[
              settings.startTime ? 'elevation-3 channel-panel' : 'mb-n6'
            ]"
          >
            <v-switch
              @click="updateStartTime"
              v-model="settings.startTime"
              :label="$t('setStartTime')"
              class="mb-4"
              data-testid="setStartTimeComposer"
            />
            <div v-if="settings.startTime">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-row align="center" justify="center">
                      <v-date-picker
                        v-model="settings.datePicker"
                        :min="getDateToday()"
                        :first-day-of-week="getFirstDayOfWeek()"
                        class="mr-1 mb-4"
                      ></v-date-picker>
                      <div>
                        <v-time-picker
                          v-model="settings.timePicker"
                          :format="settings.timePickerFormat"
                          class="mr-1 mt-7"
                        ></v-time-picker>
                        <br />
                        <v-btn-toggle v-model="settings.timePickerFormat">
                          <v-btn text value="ampm" small>
                            {{ $t("12hoursClock") }}
                          </v-btn>
                          <v-btn text value="24hr" small>
                            {{ $t("24hoursClock") }}
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <TimezoneSelect
                      :init-timezone="timezone"
                      v-on:change="setTimezone"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>

          <v-select
            v-model="settings.phoneNumType"
            v-if="showPhoneTypeFilter"
            class="mt-10"
            :label="$t('alertPhoneNumType')"
            outlined
            dense
            :items="[
              { value: 0, text: $t('all') },
              { value: 1, text: $t('work') },
              { value: 2, text: $t('private') }
            ]"
          />
          <br />
          <v-btn
            class="primary"
            @click="showConfirmDialog()"
            block
            data-testid="composerPreview"
          >
            {{ $t("preview") }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogEndOnStateErrDeputy"
      :max-width="dialogWidth.medium"
    >
      <UserError v-on:ok="dialogEndOnStateErrDeputy = false">
        <template v-slot:text>
          {{ $t("endOnStateErrorDeputy") }}
        </template>
      </UserError>
    </v-dialog>

    <v-dialog
      v-model="dialogEndOnStateErrAttempts"
      :max-width="dialogWidth.medium"
    >
      <UserError v-on:ok="dialogEndOnStateErrAttempts = false">
        <template v-slot:text>
          {{ $tc("endOnStateErrorAttempts", settings.voice.attempts) }}
        </template>
      </UserError>
    </v-dialog>

    <ConfirmAlertDialog
      v-if="dialogAlertConfirm"
      :alert-params="createAlertObject()"
      v-on:ok="registerAlert"
      v-on:cancel="dialogAlertConfirm = false"
    />

    <v-dialog v-model="dialogValidation" max-width="600">
      <v-card>
        <v-card-title>
          {{ $t("cannotSendAlert") }}
        </v-card-title>
        <v-card-text>
          <v-alert type="warning">
            <ul>
              <li v-for="error in validationErrors">
                {{ error }}
              </li>
            </ul>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogValidation = false" class="primary">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Just to please IntellJ IDE inspection -->
    <span style="display: none" class="channel-panel"></span>
  </div>
</template>

<script>
import apiAlert from "../../api/v24/api.alert";
import store from "../../store";
import AlertParams from "../../api/v24/AlertParams";
import ConfirmAlertDialog from "./ConfirmAlertDialog";
import AlertTemplatesSelect from "../alertTemplates/AlertTemplatesSelect";
import SmsComposer from "@/components/composers/SmsComposer";
import EmailComposer from "@/components/composers/EmailComposer";
import VoiceComposer from "@/components/composers/VoiceComposer";
import TextCopyButton from "@/components/composers/TextCopyButton";
import ResponseComposer from "@/components/composers/ResponseComposer";
import configVoice from "@/config/configVoice";
import alertResponseOptions from "@/util/alertResponseOptions";
import config from "@/config/config";
import user from "@/util/user";
import DepartmentSelect from "@/components/departments/DepartmentSelect";
import userLocale from "@/util/userLocale";
import TimezoneSelect from "@/components/time/TimezoneSelect.vue";
import alertMeta from "@/util/AlertMeta";
import AlertMeta from "@/util/AlertMeta";
import eventLogger from "@/util/eventLogger";
import UserError from "@/components/misc/UserError.vue";

export default {
  name: "Composer",
  components: {
    UserError,
    TimezoneSelect,
    DepartmentSelect,
    ResponseComposer,
    TextCopyButton,
    VoiceComposer,
    EmailComposer,
    SmsComposer,
    AlertTemplatesSelect,
    ConfirmAlertDialog
  },
  computed: {
    settings() {
      if (store.state.alert.settings === null) {
        let defaultSettings = getDefaultSettings();
        store.commit("setAlertSettings", defaultSettings);
        configVoice
          .getDefaultProps()
          .then(voiceProps => (this.settings.voice.voiceProps = voiceProps));
      }
      return store.state.alert.settings;
    },
    emailSender() {
      const userProfile = store.state.userProfile;
      return userProfile ? userProfile.Firmanavn : "";
    },
    userProfile() {
      return store.state.userProfile;
    },
    userIsAdmin() {
      return store.getters.getUserIsAdmin;
    },
    simMode: {
      get() {
        return store.state.alert.simMode;
      },
      set(value) {
        store.commit("setAlertSimMode", value);
      }
    },
    showPhoneTypeFilter() {
      if (this.userProfile?.Bruker._permissions.phoneNumTypes) {
        return this.settings.sendSms || this.settings.sendVoice;
      }
      return false;
    }
  },
  data: () => ({
    dialogEndOnStateErrDeputy: false,
    dialogEndOnStateErrAttempts: false,
    dialogAlertConfirm: false,
    dialogValidation: false,
    dialogWidth: config.dialogWith,
    validationErrors: [],
    smsTextValid: true,
    smsCompKey: 0,
    emailCompKey: 1,
    voiceCompKey: 2,
    resCompKey: 3,
    templCompKey: 4,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }),
  created() {
    store.dispatch("updateAlertTemplates");
  },
  methods: {
    updateStartTime() {
      this.settings.datePicker = getDateToday();
      this.settings.timePicker = getDefaultStartTime();
    },
    copySmsTextToEmail() {
      this.settings.email.text = this.settings.sms.text;
    },
    copySmsTextToVoice() {
      this.settings.voice.text = this.settings.sms.text;
    },
    setSmsSettings(smsSettings) {
      this.settings.sendSms = smsSettings.enabled;
      this.settings.sms.sender = smsSettings.sender;
      this.settings.sms.text = smsSettings.text;
    },
    setEmailSettings(emailSettings) {
      this.settings.sendEmail = emailSettings.enabled;
      this.settings.email.title = emailSettings.title;
      this.settings.email.text = emailSettings.text;
      this.settings.email.attachments = emailSettings.attachments;
    },
    addSourceInfoToEmailText() {
      this.settings.email.text += "\n\n" + config.foundEmailContactInfo;
    },
    setVoiceSettings(voiceSettings) {
      this.settings.sendVoice = voiceSettings.enabled;
      this.settings.voice.text = voiceSettings.text;
      this.settings.voice.advancedOptPanel =
        voiceSettings.advancedOptPanelState;
      this.settings.voice.attempts = voiceSettings.attempts;
      this.settings.voice.delay = voiceSettings.delay;
      this.settings.voice.deputy = voiceSettings.deputy;
      this.settings.voice.endOnState = voiceSettings.endOnState;
      this.settings.voice.voiceProps = voiceSettings.voiceProps;
      this.settings.voice.audioFile = voiceSettings.audioFile;
      if (voiceSettings.enabled === false) {
        // Deputy can not be used if alert dosen't use the "send voice" option
        this.settings.voice.deputy = false;
      }
      if (voiceSettings.audioFile) {
        eventLogger.voiceFileRecordedOnAlertPage();
      }
    },
    setResponseSettings(resOptSettings) {
      this.settings.sendResponseOptions = resOptSettings.enabled;
      this.settings.responses = resOptSettings.responses;
    },
    setDepartment(departmentId) {
      this.settings.department = departmentId;
    },
    getDateToday() {
      return getDateToday();
    },
    getFirstDayOfWeek() {
      const l = userLocale.get();
      if (l === "en" || l === "en-US" || l === "en-GB") {
        return 0;
      }
      return 1;
    },
    validateEndOnState() {
      // This if will also handle endOfState === "active", but that is not
      // in use anymore. It is kept here just in case we reintruduce it
      if (
        this.settings.voice.endOnState === "confirmed" &&
        this.settings.sendVoice &&
        !this.settings.sendResponseOptions
      ) {
        if (this.settings.voice.attempts > 0) {
          this.dialogEndOnStateErrAttempts = true;
          return false;
        } else if (this.settings.voice.deputy) {
          this.dialogEndOnStateErrDeputy = true;
          return false;
        }
      }
      return true;
    },
    showConfirmDialog() {
      const alertValid = this.validateAlert();
      if (alertValid) {
        this.dialogAlertConfirm = true;
      }
    },
    validateAlert() {
      if (!this.validateEndOnState()) {
        return false;
      }
      const alertObj = this.createAlertObject();
      this.validationErrors = alertObj.validate();
      if (this.validationErrors.length) {
        this.dialogValidation = true;
        return false;
      }
      return true;
    },
    registerAlert() {
      this.dialogAlertConfirm = false;

      const alertObj = this.createAlertObject();

      apiAlert.register(alertObj).then(response => {
        // Good time to get fresh data from server
        store.dispatch("updateGroups");
        store.commit("updateContacts");
        // Inform user
        const snackbarOptions = {
          text: this.$t("alertRegisteredOk"),
          navButton: {
            text: this.$t("goToLog"),
            path: "/log?alertId=" + response.VarslingId
          }
        };
        store.commit("setAppSnackbar", snackbarOptions);
        this.resetAlert();
        this.$emit("alert-registered");
        if (alertObj.voice) {
          eventLogger.voiceUsedInAlert(alertObj);
        }
        if (alertObj.audioFile) {
          eventLogger.voiceFileUsedInAlert(alertObj);
        }
      });
    },
    resetAlert() {
      store.commit("clearAlert");
      this.resetComposers();
    },
    resetComposers(excludeAlertTemplate = false) {
      this.smsCompKey++;
      this.emailCompKey++;
      this.voiceCompKey++;
      this.resCompKey++;
      if (!excludeAlertTemplate) {
        this.templCompKey++;
      }
    },
    createAlertObject() {
      const s = this.settings;
      const startTime = s.startTime ? s.datePicker + "T" + s.timePicker : "";
      return createAlertObject(
        this.settings,
        this.emailSender,
        startTime,
        this.timezone
      );
    },
    setSettingsFromTemplate(t) {
      this.resetComposers(true);
      let s = this.settings;
      s.name = t.alertName;
      s.sendSms = !!t.text.sms;
      s.sendEmail = !!t.text.email;
      s.sendVoice = !!t.text.voice;
      s.sms.text = t.text.sms;
      s.email.text = t.text.email;
      s.email.title = t.emailTitle;
      s.voice.text = t.text.voice;
      s.voice.attempts = t.voiceSettings.attempts;
      s.voice.delay = t.voiceSettings.delay;
      s.voice.deputy = t.voiceSettings.deputy;
      s.voice.endOnState = t.voiceSettings.endOnState;
      if (s.sendVoice) {
        s.voice.voiceProps = t.voiceSettings.voiceProps;
      } else {
        configVoice.getDefaultProps().then(voiceProps => {
          this.settings.voice.voiceProps = voiceProps;
        });
      }
      this.settings.responses = alertResponseOptions.createResponseOptArrayFromTemplate(
        t
      );
      this.settings.sendResponseOptions = t.responseOptions.find(
        response => response.text !== ""
      );
      document.getElementById("alert-name").focus();
    },
    setTimezone(newTimezone) {
      this.timezone = newTimezone.tzCode;
    }
  },
  beforeDestroy() {
    store.commit("setAlertSettings", this.settings);
  }
};

const createAlertObject = (alertSettings, emailSender, startTime, timezone) => {
  const s = alertSettings;
  let alertObj = new AlertParams(
    s.name,
    store.state.alert.simMode,
    store.state.alert.receivers
  );
  if (s.sendSms) {
    alertObj.setSms(s.sms.text, s.sms.sender);
  }
  if (s.sendEmail) {
    alertObj.setEmail(
      s.email.title,
      s.email.text,
      emailSender,
      s.email.attachments
    );
  }
  if (s.sendVoice) {
    alertObj.setVoice(s.voice.voiceProps, s.voice.text);
    alertObj.setEndOnState(s.voice.endOnState);
    alertObj.setAudioFile(s.voice.audioFile);
    if (s.voice.attempts > 0) {
      alertObj.setVoiceAttempts(s.voice.attempts, s.voice.delay);
    }
  }
  if (s.sendResponseOptions) {
    alertObj.setResponseOptions(s.responses);
  }
  const alertMeta = new AlertMeta();
  const groupsUsed = store.state.alert.selectedGroups.forEach(groupUsed => {
    alertMeta.setGroupUsed(groupUsed.Navn);
  });
  alertObj.setMeta(alertMeta);
  alertObj.setDepartment(s.department);
  alertObj.setStartTime(startTime, timezone);
  alertObj.setUseDeputies(s.voice.deputy);
  alertObj.setNumberTypeFilter(s.phoneNumType);
  return alertObj;
};

const getDateToday = () => {
  return new Date().toISOString().substr(0, 10);
};

const getDefaultStartTime = () => {
  const d = new Date();
  let hour = d.getHours();
  hour++;
  if (hour > 24) {
    hour = 10;
  }
  return hour.toString() + ":00";
};

const getDefaultSettings = () => {
  let defaultSettings = {
    name: "",
    sendSms: false,
    sendEmail: false,
    sendVoice: false,
    sendResponseOptions: false,
    responsePanel: 0,
    simMode: store.state.alert.simMode,
    startTime: false,
    datePicker: getDateToday(),
    timePicker: getDefaultStartTime(),
    timePickerFormat: getTimepickerFormat(),
    // timePickerFormat: "24hr",
    sms: {
      text: "",
      sender: ""
    },
    email: {
      title: "",
      text: "",
      attachments: []
    },
    voice: {
      voiceProps: null,
      attempts: configVoice.defaultSetting.attempts,
      delay: configVoice.defaultSetting.delay,
      deputy: configVoice.defaultSetting.deputy,
      text: "",
      endOnState: configVoice.defaultSetting.endOnState,
      advancedOptPanel: null,
      audioFile: null
    },
    responses: alertResponseOptions.getEmpty(),
    department: user.getSelectedDepartment(),
    phoneNumType: 0
  };

  // Handles situations where the user has only one department and "selected
  // department" is not set
  const { Admin, Avdeling } = user.profile.Bruker;
  if (!Admin && !defaultSettings.department) {
    defaultSettings.department = Avdeling;
  }

  return defaultSettings;
};

const getTimepickerFormat = () => {
  let locale = userLocale.get();
  if (!locale) {
    locale = config.getDefaultLanguage();
  }
  if (!locale) {
    // Just in case
    locale = "en";
  }
  const hourCycle = Intl.DateTimeFormat(locale, {
    hour: "numeric"
  }).resolvedOptions().hourCycle;
  return hourCycle === "h23" ? "24hr" : "ampm";
};
</script>
<style scoped>
.channel-panel {
  border-radius: 4px;
  padding: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>
