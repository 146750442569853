<template>
  <v-autocomplete
    v-model="kommune"
    @change="kommuneSelected"
    :items="kommuner"
    persistent-hint
    :item-text="getItemText"
    item-value="Nr"
    label="Kommune"
    return-object
    outlined
  ></v-autocomplete>
</template>

<script>
import apiKommune from "@/api/v24/api.kommune";

export default {
  name: "KommuneAutocomplete",

  data() {
    return {
      kommune: null,
      kommuner: []
    };
  },

  created() {
    apiKommune.getKommuner().then(kommuner => {
      this.kommuner = kommuner;
    });
  },

  methods: {
    kommuneSelected() {
      this.$emit("change", this.kommune);
    },
    getItemText(kommune) {
      return kommune.Navn + " - " + kommune.Nr;
    }
  }
};
</script>
