<template>
  <div>
    <span v-if="!contact.Vikarer || !contact.Vikarer.length">
      {{ $tc("deputiesPlur", 0) }}
    </span>
    <div v-else class="elevation-2 mb-2">
      <div class="text-subtitle-2 ml-2 mt-2">
        {{ $tc("deputiesPlur", contact.Vikarer.length) }}
      </div>
      <v-list-item
        two-line
        v-for="deputy in contact.Vikarer"
        :key="deputy.Id"
        dense
      >
        <v-list-item-content>
          <v-list-item-title class="caption">
            {{ deputy.Navn }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <ContactDataChips
              :contact="deputy"
              :active-color="true"
              :x-small="true"
            />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import ContactDataChips from "@/components/contacts/ContactDataChips";

export default {
  name: "ContactDuputyChips",
  components: { ContactDataChips },
  props: {
    contact: {
      type: Object
    }
  }
};
</script>
