<template>
  <v-card data-testid="receiverPickerVCard">
    <v-card-title>
      {{ $t("selecteContactsForAlert") }}
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="source"
        outlined
        :items="receiverSources"
        :label="$t('source')"
        data-testid="reciverPickerSelector"
      />
      <div v-show="source === 'groups'">
        <GroupPicker />
      </div>
      <div
        v-show="source === 'contacts'"
        data-testid="receiverPickerContactPicker"
      >
        <ContactPicker />
      </div>
      <div v-show="source === 'import'">
        <v-btn
          @click="csvImportDialog = true"
          class="primary"
          data-testid="receiverPickerCSVImportBtn"
        >
          {{ $t("importFromCsv") }}
        </v-btn>
        <v-dialog
          v-model="csvImportDialog"
          :fullscreen="$vuetify.breakpoint.xs"
        >
          <GroupImport
            file-type="csv"
            :alert-mode="true"
            v-on:cancel="csvImportDialog = false"
            v-on:done="importDone"
          />
        </v-dialog>
      </div>
      <div data-testid="reciverPickerExtra" v-show="source === 'extra'">
        <ExtraAlertReceiver />
      </div>
      <div v-show="source === 'dsf'">
        <DsfPicker />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import GroupPicker from "./GroupPicker";
import ContactPicker from "./ContactPicker";
import GroupImport from "../import/GroupImport";
import ExtraAlertReceiver from "./ExtraAlertReceivers";
import store from "../../store";
import DsfPicker from "@/components/receivers/DsfPicker";
import eventLogger from "@/util/eventLogger";

export default {
  name: "ReceiverPicker",

  components: {
    DsfPicker,
    ExtraAlertReceiver,
    GroupImport,
    ContactPicker,
    GroupPicker
  },

  computed: {
    receiverSources() {
      const rSources = [
        { text: this.$t("groups"), value: "groups" },

        { text: this.$t("contacts"), value: "contacts" }
      ];
      if (this.permissionContacts) {
        rSources.push({ text: this.$t("importFromFile"), value: "import" });
      }
      rSources.push({ text: this.$t("oneTimeReceiver"), value: "extra" });
      if (this.permissionDsf) {
        rSources.push({ text: this.$t("folkeregisteret"), value: "dsf" });
      }
      return rSources;
    }
  },

  data: () => ({
    source: "groups",
    csvImportDialog: false,
    permissionDsf: false,
    permissionContacts: false
  }),

  created() {
    store.dispatch("getUserPermissions").then(permissions => {
      this.permissionDsf = permissions.dsfModule;
      this.permissionContacts = permissions.manageContacts;
    });
  },

  methods: {
    importDone(group) {
      this.csvImportDialog = false;
      eventLogger.importReceiversFromAlertPage();
    }
  }
};
</script>
