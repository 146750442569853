<template>
  <v-card v-if="alertSettingsReady">
    <v-card-title>{{ $t("receivers") }}</v-card-title>
    <v-card-text>
      <div v-if="receivers.length">
        <div>
          <v-btn class="float-right" @click="clearAll" data-testid='resetReceiversList'>
            {{ $t("reset") }}
          </v-btn>
          <br />
          <br />
        </div>
        <div class=".body-2">{{ $t("dupesAreIgnored") }}</div>
        <v-data-table
          :items="receivers"
          :headers="headers"
          :items-per-page="10"
          :hide-default-footer="receivers.length <= 10"
          mobile-breakpoint="0"
          data-testid='listReceiversList'
        >
          <template v-slot:item.Navn="{ item }">
            <div class="contact-data">
              <div v-if="item._source.name === 'largeGroup'">
                <div class="subtitle-1">{{ item.name }}</div>
                {{ item.membersCount | numberFilter }}
                {{ $t("members").toLowerCase() }}
                <p class="caption">
                  {{ $t("largeGroupInfo") }}
                </p>
              </div>
              <div v-else>
                <div class="subtitle-1">{{ item.Navn }}</div>
                <ContactDataChips
                  :contact="item"
                  :active-color="true"
                />
                <ContactDuputyChips v-if="usingAlertDeputies" :contact="item" />
              </div>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="remove(item)">
              mdi-close
            </v-icon>
          </template>
        </v-data-table>
        <div v-if="!receivers.length" class="text-center">
          <i>{{ $t("noneSelected") }}</i>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "../../store/index";
import ContactDataChips from "../contacts/ContactDataChips";
import ContactDuputyChips from "@/components/receivers/ContactDuputyChips";
import numberFilter from "@/filters/numberFilter";

export default {
  name: "ReceiversList",
  components: { ContactDuputyChips, ContactDataChips },
  computed: {
    alertSettingsReady() {
      return !!store.state.alert.settings;
    },
    receivers() {
      return store.state.alert.receivers;
    },
    usingAlertDeputies() {
      const settings = store.state.alert.settings;
      return settings && settings.voice.deputy;
    }
  },
  data: () => ({
    headers: [],
    numberFilter: numberFilter
  }),
  created() {
    this.headers = [
      {
        text: this.$t("name"),
        align: "start",
        sortable: true,
        value: "Navn"
      },
      {
        text: "",
        align: "end",
        sortable: false,
        value: "actions"
      }
    ];
  },
  methods: {
    remove(contact) {
      store.commit("removeAlertReceiver", contact);
    },
    clearAll() {
      store.commit("clearAlertReceivers");
    }
  }
};
</script>

<style scoped>
.contact-data {
  margin-bottom: 4px;
}
</style>