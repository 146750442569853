<template>
  <div>
    <LoadingSpinner v-if="contacts === null" />
    <div v-else>
      <v-row justify="end">
        <CreateContactBtn
          v-if="usrHasEditPermission"
          v-on:click="dialogCreateContact = true"
          class="mr-6 mt-n4 mb-n2"
          :small="true"
          data-testid="contactPickerNewBtn"
        />
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field v-model.trim="search" :label="$t('search')" />
          <DataTable
            v-on:item-selected="toggle"
            v-on:toggle-select-all="toggleAll"
            v-model="store.state.alert.selectedContacts"
            show-select
            :single-select="false"
            :headers="headers"
            item-key="Id"
            :items="contacts"
            :page="pageNumber"
            class="elevation-1"
            mobile-breakpoint="0"
            :server-items-length="contactsTotal"
            v-on:update:options="onOptionsUpdate"
            data-testid="contactPickerTable"
          >
            <template
              v-slot:item.actions="{ item }"
              v-if="usrHasEditPermission"
            >
              <v-icon small @click="edit(item)" v-if="showEditIcon(item)">
                mdi-eye
              </v-icon>
            </template>
          </DataTable>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="dialogContactDetails"
      v-if="dialogContactDetails"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="1200"
    >
      <ContactDetails
        :contact-id="contact.Id"
        v-on:close="closeDetailsDialog"
        v-on:delete="closeDetailsDialog"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogCreateContact"
      v-if="dialogCreateContact"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="1200"
    >
      <ContactDetails
        :contact-id="0"
        v-on:close="dialogCreateContact = false"
      />
    </v-dialog>
  </div>
</template>

<script>
/**
 * Table with all contacts user can pick from.
 * Selected state is held by Vuex.
 */
import LoadingSpinner from "../misc/LoadingSpinner";
import store from "../../store/index";
import ContactDetails from "../contacts/ContactDetails";
import ContactSource from "@/util/ContactSource";
import apiContact from "@/api/v24/api.contact";
import debounce from "@/util/debounce";
import config from "@/config/config";
import CreateContactBtn from "@/components/contacts/CreateContactBtn";
import DataTable from "@/components/dataTable/DataTable.vue";

export default {
  name: "ContactPicker",
  components: { DataTable, CreateContactBtn, ContactDetails, LoadingSpinner },
  computed: {
    headers() {
      let headers = [{ text: this.$t("name"), value: "Navn" }];
      if (store.getters.getShowDepartmentInfoInLists) {
        headers.push({
          text: this.$t("department"),
          value: "AvdelingNavn",
          cellClass: "font-weight-light",
          sortable: false
        });
      }
      headers.push(
        {
          text: this.$t("jobTitle"),
          value: "Stilling",
          hide: "mdAndDown",
          cellClass: "font-weight-light"
        },
        { text: "", value: "actions", sortable: false, align: "end" }
      );
      return headers.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide]);
    },
    contactsUpdate() {
      return store.state.contactsUpdate;
    },
    itemsPerPage() {
      return store.getters.getPreferredNumTableRows;
    }
  },
  data: () => ({
    pageNumber: 1,
    sortBy: "",
    sortDesc: false,
    store: store,
    contacts: null,
    contactsTotal: 0,
    search: "",
    contact: null,
    dialogContactDetails: false,
    dialogCreateContact: false,
    usrHasEditPermission: false,
    tblFooterProps: {
      itemsPerPageOptions: config.fixedTblItemsPerPageOptions
    }
  }),
  watch: {
    search: debounce.registerInput(function(newVal) {
      this.pageNumber = 1;
      this.searchContacts(newVal);
    }, 500),
    contactsUpdate() {
      this.fetchContacts();
    }
  },
  created() {
    this.fetchContacts();
    store.dispatch("getUserPermissions").then(permissions => {
      if (permissions.manageContacts && !permissions.activeDirProvisioning) {
        this.usrHasEditPermission = true;
      }
    });
  },
  methods: {
    showEditIcon(contact) {
      return !store.state.alert.selectedContacts.some(
        selContact => selContact.Id === contact.Id
      );
    },
    edit(contact) {
      this.contact = contact;
      this.dialogContactDetails = true;
    },
    closeDetailsDialog() {
      this.dialogContactDetails = false;
    },
    toggle(event) {
      const contact = event.item;
      if (event.value === true) {
        this.addToReceivers(contact);
      } else {
        this.removeReceivers(contact);
      }
    },
    toggleAll(event) {
      if (event.value === true) {
        event.items.forEach(contact => {
          this.addToReceivers(contact);
        });
      } else {
        event.items.forEach(contact => {
          this.removeReceivers(contact);
        });
      }
    },
    addToReceivers(contact) {
      contact._source = getContactSource(contact);
      store.commit("addAlertReceiver", contact);
    },
    removeReceivers(contact) {
      store.commit("removeAlertReceiverByContactId", contact.Id);
    },
    fetchContacts() {
      if (this.search) {
        this.searchContacts(this.search);
      } else {
        apiContact
          .getMultiple(
            this.pageNumber,
            this.itemsPerPage,
            this.sortBy,
            this.sortDesc
          )
          .then(res => this.setContacts(res));
      }
    },
    searchContacts(searchStr) {
      apiContact
        .search(
          searchStr,
          this.pageNumber,
          this.itemsPerPage,
          this.sortBy,
          this.sortDesc
        )
        .then(res => this.setContacts(res));
    },
    onOptionsUpdate(options) {
      this.pageNumber = options.page;
      this.sortBy = options.sortBy.length ? options.sortBy[0] : "";
      this.sortDesc = options.sortDesc.length ? options.sortDesc[0] : false;
      this.fetchContacts();
    },
    setContacts(fetchResult) {
      this.contacts = fetchResult.Kontakter;
      this.contactsTotal = fetchResult.AntallTotalt;
    }
  }
};

const getContactSource = contact => {
  const contactSource = new ContactSource();
  contactSource.setContact(contact.Id);
  return contactSource.get();
};
</script>
