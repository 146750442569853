<template>
  <div>
    <v-btn data-testid='extraAlertReceiversAdd' @click="createDialog = true">{{ $t("add") }}</v-btn>
    <v-dialog v-model="createDialog" v-if="createDialog" max-width="500">
      <ExtraAlertReceiver v-on:done="addReceiver" />
    </v-dialog>
  </div>
</template>

<script>
import Contact from "../../util/Contact";
import ExtraAlertReceiver from "./ExtraAlertReceiver";
import store from "../../store/index";
import ContactSource from "@/util/ContactSource";

export default {
  name: "ExtraAlertReceivers",
  components: { ExtraAlertReceiver },
  data: () => ({
    createDialog: false,
    personDummyId: 0
  }),

  methods: {
    addReceiver(receiver) {
      if (receiver) {
        const extraReceiver = new Contact(receiver.name);
        extraReceiver.setId(this.personDummyId);
        if (receiver.mobile1?.number) {
          const mobile1 = receiver.mobile1;
          extraReceiver.setMobile1(mobile1.countryCode + mobile1.number);
        }
        if (receiver.mobile2?.number) {
          const mobile2 = receiver.mobile2;
          extraReceiver.setMobile2(mobile2.countryCode + mobile2.number);
        }
        if (receiver.mobile3?.number) {
          const mobile3 = receiver.mobile3;
          extraReceiver.setMobile3(mobile3.countryCode + mobile3.number);
        }
        if (receiver.landline1?.number) {
          const landline1 = receiver.landline1;
          extraReceiver.setLandline1(landline1.countryCode + landline1.number);
        }
        if (receiver.landline2?.number) {
          const landline2 = receiver.landline2;
          extraReceiver.setLandline2(landline2.countryCode + landline2.number);
        }
        extraReceiver.setEmail(receiver.email);

        const contactSrc = new ContactSource();
        contactSrc.setExtra(Date.now());
        extraReceiver.setSource(contactSrc);
        store.commit("addAlertReceiver", extraReceiver.getAsApiObject());
        store.commit("setAppSnackbar", this.$t("extraReceiverAdded"));
      }
      this.createDialog = false;
    }
  }
};
</script>
