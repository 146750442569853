import api from "@/api/v24/api";

const apiDsf = {
  searchName(kommunenr, surname, firstName = "") {
    const params = {
      Kommunenr: kommunenr,
      Navn: firstName + "%" + surname
    };
    return api.execute("searchNavn", params, "Personer");
  },

  /**
   * @param id ID returned from searchName
   */
  getPersonInfo(id) {
    const params = { Id: id };
    return api.execute("HentKontaktinfo", params, "Kontakt");
  },

  getByAgeCount(knr, fromYear, toYear) {
    const params = {
      Knr: knr,
      FraAar: fromYear,
      TilAar: toYear
    };
    return api.execute("UttrekkFolkeregisteretAntall", params);
  },

  getByAge(knr, fromYear, toYear, use1881, useDummy1881) {
    const params = {
      Knr: knr,
      FraAar: fromYear,
      TilAar: toYear,
      Oppslag1881: use1881,
      Dummy1881: useDummy1881
    };
    return api.execute("UttrekkFolkeregisteret", params);
  }
};

export default apiDsf;
