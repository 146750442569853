<template>
  <v-form @submit.prevent="add" v-model="formValid" autocomplete="off">
    <v-card>
      <v-card-title>
        {{ $t("addExtraReceiver") }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="extraReceiver.name"
          maxlength="50"
          :rules="validationRules.requiredRule"
          :label="$t('name')"
          outlined
          data-testid="addExtraPerson_name"
        />
        <ContactDataManagement
          :contact="extraReceiver"
          v-on:change="setContactData"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel" data-testid="addExtraPerson_cancel">{{
          $t("cancel")
        }}</v-btn>
        <v-btn
          type="submit"
          class="primary"
          :disabled="!receiverValid()"
          data-testid="addExtraPerson_submit"
        >
          {{ $t("ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formValidationRules from "../../util/formValidationRules";
import ContactDataManagement from "@/components/contacts/ContactDataManagement";
import eventLogger from "@/util/eventLogger";

export default {
  name: "ExtraAlertReceiver",
  components: { ContactDataManagement },
  data: () => ({
    extraReceiver: {
      name: "",
      mobile1: null,
      mobile2: null,
      mobile3: null,
      landline1: null,
      landline2: null,
      email: ""
    },
    validationRules: formValidationRules,
    formValid: false
  }),

  methods: {
    cancel() {
      this.$emit("done");
    },
    setContactData(newContactData) {
      this.extraReceiver.mobile1 = newContactData.mobile1;
      this.extraReceiver.mobile2 = newContactData.mobile2;
      this.extraReceiver.mobile3 = newContactData.mobile3;
      this.extraReceiver.landline1 = newContactData.landline1;
      this.extraReceiver.landline2 = newContactData.landline2;
      this.extraReceiver.email = newContactData.email;
    },
    receiverValid() {
      if (!this.formValid) {
        return false;
      }
      const e = this.extraReceiver;
      return (
        e.mobile1?.number ||
        e.mobile2?.number ||
        e.mobile3?.number ||
        e.landline1?.number ||
        e.landline2?.number ||
        e.email
      );
    },
    add() {
      eventLogger.extraReceiverUsed();
      this.$emit("done", this.extraReceiver);
    }
  }
};
</script>
