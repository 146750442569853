<template>
  <div v-if="contact">
    <v-chip
      class="chip"
      :small="!xSmall"
      :x-small="xSmall"
      v-if="contact.Mobil"
      :color="getChipColor('M')"
    >
      <v-avatar left>
        <v-icon small>
          mdi-cellphone
        </v-icon>
      </v-avatar>
      {{ contact.Mobil }}
    </v-chip>
    <v-chip
      class="chip"
      :small="!xSmall"
      :x-small="xSmall"
      v-if="contact.Mobil2"
      :color="getChipColor('M')"
    >
      <v-avatar left>
        <v-icon small>
          mdi-cellphone
        </v-icon>
      </v-avatar>
      {{ contact.Mobil2 }}
    </v-chip>
    <v-chip
      class="chip"
      :small="!xSmall"
      :x-small="xSmall"
      v-if="contact.Fast"
      :color="getChipColor('F')"
    >
      <v-avatar left>
        <v-icon small>
          mdi-phone
        </v-icon>
      </v-avatar>
      {{ contact.Fast | phoneFilter }}
    </v-chip>
    <v-chip
      class="chip"
      :small="!xSmall"
      :x-small="xSmall"
      v-if="contact.Fast2"
      :color="getChipColor('F')"
    >
      <v-avatar left>
        <v-icon small>
          mdi-phone
        </v-icon>
      </v-avatar>
      {{ contact.Fast2 | phoneFilter }}
    </v-chip>
    <v-chip
      class="chip"
      :small="!xSmall"
      :x-small="xSmall"
      v-if="contact.Epost"
      :color="getChipColor('E')"
    >
      <v-avatar left>
        <v-icon small>
          mdi-email
        </v-icon>
      </v-avatar>
      {{ contact.Epost }}
    </v-chip>
  </div>
</template>

<script>
import store from "../../store";
import contactUtil from "../../util/contactUtil";
import phoneFilter from "../../filters/phoneFilter";

export default {
  name: "ContactDataChips",

  props: {
    // Type as returned from API
    contact: {
      type: Object
    },
    activeColor: {
      type: Boolean,
      default: false
    },
    xSmall: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    phoneFilter: phoneFilter
  }),

  methods: {
    getChipColor(contactType) {
      if (!this.activeColor) {
        return;
      }
      const alertSettings = store.state.alert.settings;
      const activeColor = "deep-purple lighten-3";
      const s = alertSettings;
      if (
        contactUtil.contactDataWillBeIncludedInAlert(
          contactType,
          s.sendSms,
          s.sendEmail,
          s.sendVoice
        )
      ) {
        return activeColor;
      }
      return "";
    }
  }
};
</script>

<style scoped>
.chip {
  margin: 4px;
}
</style>
