import Vue from "vue";

const phoneFilter = input => {
  let output = input.toString();
  if (output.length !== 8) {
    return output;
  }
  return (
    output.substring(0, 3) +
    " " +
    output.substring(3, 5) +
    " " +
    output.substring(5)
  );
};

Vue.filter("phoneFilter", phoneFilter);

export default phoneFilter;
