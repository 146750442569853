<template>
  <v-btn
    @click="click"
    :small="small"
    v-if="usrCanCreateGroup"
    data-testid="newGroup"
    :block="block"
  >
    <v-icon left>mdi-plus</v-icon>
    {{ $t("newGroup") }}
  </v-btn>
</template>

<script>
import user from "@/util/user";

export default {
  name: "CreateGroupBtn",

  data: () => ({
    usrCanCreateGroup: false
  }),

  props: {
    small: {
      default: false,
      type: Boolean
    },
    block: {
      default: false,
      type: Boolean
    }
  },

  created() {
    user.canEditGroups().then(canEdit => {
      this.usrCanCreateGroup = canEdit;
    });
  },

  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>
